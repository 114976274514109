<template>
  <div>
    <a-spin :spinning="loading" size="large">
      <a-form ref="form" :model="modelRef" scrollToFirstError name="form"
              :labelCol="{span: 4 }"
              :wrapperCol="{span: 18 }"
      >

        <h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
          免运费配置</h4>

        <a-form-item name="freeOrderTotalPrice" :rules="[{ required: true, message: '必填项不允许为空' }]" style="margin-left: 200px;" extra="不填则不开启免运费，任意商品满x元包邮">
          满 <a-input-number :min="0" :precision="0" style="width: 200px;" v-model:value="modelRef.freeOrderTotalPrice" placeholder="请输入包邮金额"></a-input-number> 元包邮
        </a-form-item>

        <a-form-item name="freeOrderItemNum" :rules="[{ required: true, message: '必填项不允许为空' }]" style="margin-left: 200px;" extra="不填则不开启免运费，购买x件下方选择商品包邮">
          满 <a-input-number :min="0" :precision="0" style="width: 200px;" v-model:value="modelRef.freeOrderItemNum" placeholder="请输入包邮件数"></a-input-number> 件包邮
        </a-form-item>

        <a-form-item label="参与满x件包邮商品">
          <a-form-item-rest>
            <selectGoods v-model:value="goodsIdList" :selectItems="goodsList" @change="(dataList) => {goodsList = dataList}"></selectGoods>
          </a-form-item-rest>
          <div v-if="goodsList.length" style="margin: 10px 0;">
            <a-table rowKey="id" :columns="goodsColumns" :dataSource="goodsList" size="small" :pagination="false">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onDeleteGoods(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
          <a-button v-permission="['mall_freight_free_edit']" type="primary" html-type="submit" @click="onSubmit">提交</a-button>
        </a-form-item>
      </a-form>

    </a-spin>
  </div>
</template>

<script>
import selectGoods from "@/components/select/selectGoods";
import {getFreeFeeSettingDetail, updateFreeFeeSetting} from "../../../../service/modules/mall";
export default {
  components: {
    selectGoods,
  },
  data() {
    return {
      loading: false,
      modelRef: {

      },
      goodsIdList: [],
      goodsList: [],
      goodsColumns: [{
        title: '商品名称',
        dataIndex: 'mainTitle'
      }, {
        title: '商品编码',
        dataIndex: 'id'
      }, {
        title: '商品分类',
        dataIndex: 'classifyName',
      }, {
        title: '操作',
        key: 'action'
      }],
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        let ret = await getFreeFeeSettingDetail({});
        this.loading = false;
        if(ret.code === 200) {
          if(ret.data.freeGoodsIdList) ret.data.freeGoodsIdList.forEach(item => this.goodsIdList.push(item + ''))
          this.goodsList = ret.data.freeGoodsList;

          this.modelRef = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDeleteGoods(item, index) {
      let i = this.goodsIdList.indexOf(item.id);
      this.goodsIdList.splice(i, 1);
      this.goodsList.splice(index, 1);
    },
    onSubmit() {
      this.$refs.form.validateFields().then(async () => {
        this.modelRef.freeGoodsIdList = [];
        this.goodsList.forEach(item => this.modelRef.freeGoodsIdList.push(Number(item.id)))

        let postData = JSON.parse(JSON.stringify(this.modelRef));
        this.loading = true;
        try {
          let ret = await updateFreeFeeSetting(postData);
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.getData();
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
  }
}
</script>

<style>
</style>
